<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Pagination, Autoplay } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import type { HeroData, HeroSliderSettings } from '~/@types/cms';

    declare interface HeroSliderProps {
        data: HeroData[];
        componentIndex?: number;
        settings?: HeroSliderSettings;
    }

    const nuxtApp = useNuxtApp();
    const props = defineProps<HeroSliderProps>();
    const slides = computed(() => props.data.filter(shouldDisplayWidget));

    const { setMainSwiper, checkSwipe, swipeLeft, swipeRight, isBeginning, isEnd, ssrIsActive } = useGallery();

    const sendToGtm = () => {
        if (checkSwipe()) {
            nuxtApp.$gtm.pushEvent({
                event: 'click_hero_slider',
                slider_name: `slider_${props.componentIndex}`,
            });
        }
    };
</script>

<template>
    <section class="org-hero-slider relative flex h-screen-mobile lg:h-screen-desktop">
        <div class="w-full">
            <nuxt-error-boundary @error="() => {}">
                <swiper
                    :slides-per-view="1"
                    :loop="data.length > 1"
                    :lazy="true"
                    :pagination="{
                        clickable: true,
                        dynamicBullets: false,
                    }"
                    direction="horizontal"
                    :modules="[Pagination, Autoplay]"
                    :autoplay="settings?.autoplay === 'yes' ? { delay: 5000, pauseOnMouseEnter: true } : false"
                    class="h-full"
                    @swiper="setMainSwiper"
                    @slide-change="sendToGtm">
                    <swiper-slide
                        v-for="(hero, index) in slides"
                        :key="index"
                        v-slot="{ isActive }">
                        <mol-hero
                            :hero-data="hero"
                            :slider-index="`${componentIndex}_${index}`"
                            :inert="!ssrIsActive(isActive, index)"
                            :is-first-hero="componentIndex === 0 && index === 0"
                            bottom-margin
                            full-height
                            is-slider />
                    </swiper-slide>
                </swiper>
            </nuxt-error-boundary>

            <mol-swiper-navigation
                :swipe-left="swipeLeft"
                :swipe-right="swipeRight"
                :is-beginning="isBeginning"
                :is-end="isEnd" />
        </div>
    </section>
</template>

<style scoped>
    :deep(.swiper-pagination-fraction),
    :deep(.swiper-pagination-custom),
    :deep(.swiper-horizontal > .swiper-pagination-bullets),
    :deep(.swiper-pagination-bullets.swiper-pagination-horizontal) {
        @apply bottom-3 w-fit max-md:!left-[50%] max-md:translate-x-[-50%] md:bottom-4 md:left-auto md:right-8;
    }

    :deep(.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet),
    :deep(.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet) {
        @apply h-[10px] w-[10px] scale-100 transform-none bg-white opacity-50 shadow shadow-black;
    }
    :deep(.swiper-pagination-bullet-active),
    :deep(.swiper-pagination-bullet-active-main) {
        @apply !opacity-100;
    }
</style>
